@import url("https://fonts.googleapis.com/css2?family=Quicksand&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Quicksand", sans-serif;
  margin: 0;
  overscroll-behavior-y: contain;
}

.container {
  overflow: auto;
}

.scrollContainer {
  overflow: auto;
  overflow-x: hidden;
  position: fixed;
  left: 0;
  width: 100%;
  background-color: #ccecfc;
  background-image: linear-gradient(to top, #ccecfc, #8bd5f7, #527593, #3f505f);
}

.header {
  background-color: rgba(255, 255, 255, 0.8);
  display: inline-flex;
  justify-content: space-between;
  width: 100vw;
  position: fixed;
  z-index: 1000;
}

.footer {
  background-color: rgba(255, 255, 255, 0.8);
  display: none;
  justify-content: flex-end;
  width: 100vw;
  position: fixed;
  bottom: 0px;
  z-index: 1000;
}

h1 {
  color: black;
  font-size: 50px;
  font-weight: lighter;
  display: inline-block;
  padding: 0px 50px 15px 50px;
  margin: 0;
  border-radius: 3px;
}

nav {
  padding: 20px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  transition: width 0.6s linear;
  overflow-x: hidden;
}

nav.active {
  width: 450px;
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 0;
  transition: width 0.6s linear;
}

nav.active ul {
  width: 100%;
}

nav ul li {
  transform: rotateY(0deg);
  opacity: 0;
  transition: transform 0.6s linear, opacity 0.6s linear;
}

nav.active ul li {
  opacity: 1;
  transform: rotateY(360deg);
}

nav ul a {
  position: relative;
  color: black;
  text-decoration: none;
  font-size: 20px;
  margin: 0 10px;
}

.icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  position: relative;
  height: 30px;
  width: 30px;
}

.icon:focus {
  outline: 0;
}

.icon .line {
  background-color: black;
  height: 2px;
  width: 20px;
  position: absolute;
  top: 10px;
  left: 5px;
  transition: transform 0.6s linear;
}

.icon .line2 {
  top: auto;
  bottom: 10px;
}

nav.active .icon .line1 {
  transform: rotate(-765deg) translateY(5.5px);
}

nav.active .icon .line2 {
  transform: rotate(765deg) translateY(-5.5px);
}

.stars {
  position: absolute;
  width: 100vw;
  right: 0;
  z-index: -100;
}

.stars img {
  width: 100%;
}

.moon {
  position: absolute;
  height: 400px;
  width: 400px;
  left: -60px;
  top: -60px;
}

.moon img {
  height: 100%;
  width: 100%;
}

.romy-top {
  height: 35vh;
  display: inline-block;
  position: absolute;
  right: 10vw;
  z-index: 900;
}

.romy-top img {
  height: 100%;
}

.birds-left {
  position: absolute;
  height: 200px;
  width: 200px;
  left: -200px;
  bottom: 3700px;
  transform: translateX(2000%);
  transition: transform 10s linear;
}

.birds-left img {
  height: 100%;
}

.birds-right {
  position: absolute;
  height: 200px;
  width: 200px;
  right: -200px;
  bottom: 2000px;
  transform: translateX(-2000%);
  transition: transform 10s linear;
}

.birds-right img {
  height: 100%;
  transform: scaleX(-1);
}

.balloon {
  position: absolute;
  height: 600px;
  bottom: 2200px;
  left: -400px;
  animation: 20s ease-out 0s 1 moveBalloon;
}

.balloon img {
  height: 100%;
}

.clouds {
  position: absolute;
  height: 800px;
  width: 800px;
  right: -800px;
  bottom: 2600px;
  transform: translateX(-2000%);
  transition: transform 90s linear;
}

.clouds img {
  height: 100%;
}

.clouds-left {
  position: absolute;
  height: 800px;
  width: 800px;
  left: -800px;
  bottom: 4500px;
  transform: translateX(2000%);
  transition: transform 90s linear;
  z-index: -100;
}

.clouds-left img {
  height: 100%;
  transform: scaleX(-1);
}

.clouds-left-2 {
  position: absolute;
  height: 800px;
  width: 800px;
  left: -800px;
  bottom: 5000px;
  transform: translateX(2000%);
  transition: transform 90s linear;
  z-index: -100;
}

.clouds-left-2 img {
  height: 100%;
  transform: scaleX(-1);
}

@keyframes moveBalloon {
  0% {
    transform: translateX(-100%);
  }
  10% {
    transform: translateX(90%);
  }
  100% {
    transform: translate(-100%, -1000%);
  }
}

.spaceship {
  position: absolute;
  height: 200px;
  width: 200px;
  left: -200px;
  top: 500px;
  transform: translateX(-100%);
  transition: transform 0.5s linear;
}

.spaceship.show {
  transform: translateX(200%);
}

.spaceship img {
  height: 100%;
}

.climber {
  height: 35vh;
  display: inline-block;
  position: fixed;
  left: 40%;
  transform: translate(-50%, 0%);
  z-index: 900;
}

.climber img {
  height: 100%;
}

.romy-bottom {
  height: 35vh;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 5vw;
  z-index: 900;
}

.romy-bottom img {
  height: 100%;
}

.bubble {
  position: absolute;
  background: #ffffff;
  color: #000000;
  font-family: inherit;
  font-size: 21px;
  font-weight: 800;
  line-height: 100px;
  text-align: center;
  width: 250px;
  height: 100px;
  border-radius: 100px;
  padding: 0px;
  bottom: 300px;
  left: 55px;
}
.bubble:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 35px 35px 0 0;
  border-color: #ffffff transparent transparent transparent;
  bottom: -35px;
  left: 60%;
  margin-left: -20px;
}

.cliff {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cliff img {
  width: 60%;
  height: 900px;
}

.rope {
  display: inline-block;
  position: absolute;
  background-color: #444444;
  width: 3px;
  left: 40%;
  transform: translate(-50%, 0%);
}

.belayRope {
  display: inline-block;
  position: absolute;
  background-color: #444444;
  width: 3px;
}

.btn {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  font-family: inherit;
  font-weight: 800;
  font-size: 40px;
  cursor: pointer;
  display: inline-block;
  padding: 40px 80px;
  border: none;
  border-radius: 60px;
  position: absolute;
  bottom: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 900;
}

.btn:active {
  transform: translate(-50%, -50%) scale(0.95);
}

.grass {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
}

.grass img {
  height: 25px;
  width: 25px;
}

.content {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
}

.box {
  background-color: white;
  font-family: inherit;
  color: black;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  width: 550px;
  max-width: 90vw;
  margin-top: 75px;
  margin-bottom: 75px;
  margin-right: max(min(200px, 100vw - 550px), 0px);
  border-radius: 10px;
  transform: translateX(200%);
  transition: transform 0.5s ease;
}

/* .box:nth-of-type(even) {
  transform: translateX(-400%);
} */

.box.show {
  transform: translateX(0%);
}

.box h2 {
  font-size: 25px;
  margin: 0;
  text-align: center;
}

.box ul {
  list-style-type: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 0;
}

.box .date {
  font-weight: 600;
}

@media (max-width: 760px) {
  .footer {
    display: inline-flex;
  }
  .footer nav ul a {
    font-size: 16px;
  }
  .header nav {
    display: none;
  }
  .romy-bottom {
    left: calc(35vh / -5);
  }
  .balloon {
    display: none;
  }
}
